import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Link } from '@material-ui/core';
import { texts as trans } from '../../texts/Components/ImpressumText.js';

const Impressum = () => {
    return (
        <Typography variant="inherit">
            <h2>{trans.impressum.titel}</h2>
            <p>
                {trans.impressum.address.name}
                <br />
                {trans.impressum.address.street}
                <br />
                {trans.impressum.address.postalcode_city}
                <br />
                {trans.label_tel} {trans.impressum.address.tel}
                <br />
                {trans.label_fax} {trans.impressum.address.fax}
                <br />
                {trans.label_mail}{' '}
                <Link
                    variant="body1"
                    href={'mailto:' + trans.impressum.address.mail}
                >
                    {trans.impressum.address.mail}
                </Link>
                <br />
                {trans.label_internet}{' '}
                <a
                    href={trans.impressum.address.internet}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    {trans.impressum.address.internet}
                </a>
                <br />
            </p>
            <p>{trans.impressum.text}</p>

            <h4>{trans.location.title}</h4>
            <p>{trans.location.text}</p>

            <h4>{trans.registration.title}</h4>
            <p>{trans.registration.text}</p>

            <h4>{trans.taxNumber.title}</h4>
            <p>{trans.taxNumber.text}</p>

            <h4>{trans.technique.title}</h4>
            <p>
                {trans.technique.address.name}
                <br />
                {trans.technique.address.department}
                <br />
                {trans.technique.address.street}
                <br />
                {trans.technique.address.postalcode_city}
                <br />
            </p>
            <p>
                {trans.label_tel} {trans.technique.address.tel}
                <br />
                {trans.label_fax} {trans.technique.address.fax}
                <br />
                {trans.label_mail}{' '}
                <Link
                    variant="body1"
                    href={'mailto:' + trans.technique.address.mail}
                >
                    {trans.technique.address.mail}
                </Link>
                <br />
            </p>

            <h4>{trans.respsonsible.title}</h4>
            <p>{trans.respsonsible.text}</p>

            <h4>{trans.disclaimer.title}</h4>
            <p>{trans.disclaimer.text}</p>

            <h4>{trans.right.title}</h4>
            <p>{trans.right.text}</p>

            <h4>{trans.further_info.title}</h4>
            <p>{trans.further_info.text}</p>
        </Typography>
    );
};

export default Impressum;
