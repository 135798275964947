import React from 'react';
import FooterGrid from '../../../components/Layout/Footer/FooterGrid';
import FooterGridSupport from '../../../components/Layout/Footer/FooterGridSupport';
import FooterGridDirective from '../../../components/Layout/Footer/FooterGridDirective';
import { texts } from '../../../texts/Footer/FooterTexts';

const footerData = [
    {
        id: 1,
        headline: texts.info.headline,
        body1: texts.info.body1,
        body2: texts.info.body2,
        uris1: [texts.info.bmwk_url1],
        uris2: [texts.info.bmwk_url2],
        render: FooterGridDirective,
    },
    {
        id: 2,
        headline: texts.support.headline,
        body: texts.support.body,
        mail: {
            link: texts.support.mail.link,
            text: texts.support.mail.text,
        },
        tel: texts.support.tel,
        faq: {
            link: texts.support.faq.link,
            text: texts.support.faq.text,
        },
        render: FooterGridSupport,
    },
    {
        id: 3,
        headline: texts.technical.headline,
        body: texts.technical.body,
        mail: {
            link: texts.technical.mail.link,
            text: texts.technical.mail.text,
        },
        tel: texts.technical.tel,
        render: FooterGridSupport,
    },
];

function fetchFooterData() {
    return footerData;
}

const FooterGridContainer = () => {
    const footerData = fetchFooterData();

    return <FooterGrid footerData={footerData} />;
};

export default FooterGridContainer;
