export const texts = {
    info: {
        headline: 'Informationen zu "Digital Jetzt"',
        body1: '"Digital Jetzt" ist eine Fördermaßnahme des {%Bundesministeriums für Wirtschaft und Klimaschutz%}.',
        body2: 'Informationen finden Sie auf der {%Webseite des Bundesministeriums für Wirtschaft und Klimaschutz%}.',
        bmwk_url1: 'https://www.bmwk.de/',
        bmwk_url2:
            'https://www.bmwk.de/Redaktion/DE/Dossier/digital-jetzt.html',
    },
    support: {
        headline: 'Inhaltlicher Support',
        body: [
            'Deutsches Zentrum für Luft- und Raumfahrt e. V.',
            'Projektträger (DLR PT)',
            'Bonn',
        ],
        mail: {
            link: 'digitaljetzt@dlr.de?subject=Digital-Jetzt Anfrage',
            text: 'digitaljetzt@dlr.de',
        },
        tel: 'Hotline: 0228 38212315',
        faq: {
            link: '/faq',
            text: 'Fragen und Antworten',
        },
    },
    technical: {
        headline: 'Technischer Support',
        body: [
            'Deutsches Zentrum für Luft- und Raumfahrt e. V. ',
            'Projektträger (DLR PT)',
            'Berlin',
        ],
        mail: {
            link: 'pt-webservice@dlr.de?subject=Digital-Jetzt Anfrage',
            text: 'pt-webservice@dlr.de',
        },
        tel: 'Hotline: 030 67055-767',
    },
};
