export const texts = {
    label_tel: 'Tel.:',
    label_fax: 'Fax.:',
    label_mail: 'E-Mail:',
    label_internet: 'Internet:',
    impressum: {
        titel: 'Impressum gem. § 5 DDG und § 55 Rundfunkstaatsvertrag',
        address: {
            name: 'Deutsches Zentrum für Luft- und Raumfahrt e. V. (DLR)',
            street: 'Linder Höhe',
            postalcode_city: '51147 Köln',
            tel: 'Tel.: 02203 601-0',
            fax: 'Fax: 02203 67310',
            mail: 'contact-dlr@dlr.de',
            internet: 'https://www.dlr.de/',
        },
        text:
            'Gesetzlicher Vertreter des DLR ist der Vorstand, bestehend aus Prof. Dr.-Ing. Anke Kaysser-Pyzalla ' +
            '(Vorsitzende des Vorstands), Klaus Hamacher (Stellvertretender Vorsitzender des Vorstands), Prof. Rolf ' +
            'Henke, Prof. Dr. rer. nat. Hansjörg Dittus, Prof. Dr.-Ing. Karsten Lemmer und Dr.-Ing. Walther Pelzer.',
    },
    location: {
        title: 'Sitz des Vorstands',
        text:
            'Deutsches Zentrum für Luft- und Raumfahrt (DLR), Linder Höhe, 51147 Köln. Der Vorstand kann auch ' +
            'durch von ihm ermächtigte Personen vertreten werden. Auskünfte über den Umfang der Ermächtigung erteilt ' +
            'der Leiter Allgemeine Rechtsangelegenheiten des DLR, Linder Höhe, 51147 Köln.',
    },
    registration: {
        title: 'Registergericht / Registernummer:',
        text: 'Amtsgericht Bonn, VR 2780',
    },
    taxNumber: {
        title: 'Umsatzsteuer-Identifikationsnummer:',
        text: 'DE 121965658',
    },
    technique: {
        title: 'Technische Betreuung:',
        address: {
            name: 'DLR Projektträger',
            department: 'IKT-Service',
            street: 'Sachsendamm 61',
            postalcode_city: '10829 Berlin',
            tel: '030 67055-767',
            fax: '030 67055-712',
            mail: 'pt-webservice@dlr.de',
        },
    },
    respsonsible: {
        title: 'Verantwortliche(r) nach § 55, Abs. 2, Rundfunkstaatsvertrag',
        text: 'Steffen Bruckner',
    },
    disclaimer: {
        title: 'Haftungshinweis:',
        text:
            'Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer ' +
            'Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich. Falls ' +
            'Sie Fehler inhaltlicher oder technischer Art entdecken, teilen Sie uns dies bitte mit.',
    },
    right: {
        title: 'Nutzungs- und Verwertungsrechte an den Bildern:',
        text:
            'Die Rechte aller auf dieser Website verwendeten Bilder liegen, sofern nicht anders angegeben, ' +
            'beim z.B. DLR Projektträger. Bildquellen sind im Bildtext (z.B. durch mouse over) entsprechend gekennzeichnet',
    },
    further_info: {
        title: 'Weitere Informationen',
        text:
            'Diese Internetseiten enthalten Links zu anderen Websites. Wir sind nicht verantwortlich für die ' +
            'Bestimmungen zum Datenschutz oder den Inhalt dieser Websites.',
    },
};
